


import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { TEventStat, TProgramListItem, TSessionRatings } from '@/_modules/statistics/types/event-stat.type';
import { TColumns } from '@/_modules/statistics/types/column.type';
import { TEventStatRow } from '@/_modules/statistics/types/event-stat/event-stat-row.type';
import { TEvent } from '@/_types/event.type';
import FileHelper from '@/_helpers/file.helper';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import _cloneDeep from 'lodash.clonedeep';
import promoProgramApi, { TConferenceProgramRating } from '@/_modules/promo-program/api/promo-program.api';
import statisticsApi, { TGetStatProgramQuestionnairesResponse } from '@/_modules/statistics/api/statistics.api';
import {TContact} from '@/_types/contact.type';
import EventHelper from '@/_helpers/event.helper';

enum eventStatTabNames {
  NONE = '_nothing_',
  COMPANIES = 'companies',
  SESSIONS = 'sessions',
  POLLS = 'polls',
}

@Component
export default class StatisticsEvent extends Vue {

  @Getter('statisticStore/event') eventStat: TEventStat;
  @Getter('statisticStore/isLoadingEvent') isEventStatLoading: boolean;
  @Getter('_eventStore/event') event: TEvent;
  @Getter('_eventStore/isLoading') isEventLoading: boolean;
  @Getter('promoPageStore/contact') myself: TContact;

  public eventStatTabNames: typeof eventStatTabNames = eventStatTabNames;
  public eventStatCurrentTab: eventStatTabNames = eventStatTabNames.COMPANIES;
  public sessionRatings: TSessionRatings = {};
  public isRatingsLoaded: boolean = false;
  public isPollStatsLoaded: boolean = false;
  public columns: TColumns[] = [
    {
      label: 'result.event.company_name',
      field: 'name',
    },
    {
      label: 'result.event.contacts',
      field: 'contacts',
      type: 'number',
      extraClasses: 'left-border',
    },
    {
      label: 'result.event.photos',
      field: 'photos',
      type: 'number',
      extraClasses: 'left-border',
    },
    {
      label: 'result.event.files',
      field: 'files',
      type: 'number',
      extraClasses: 'left-border',
    },
    {
      label: 'result.event.products',
      field: 'products',
      type: 'number',
      extraClasses: 'left-border',
    },
    // { // TODO: refresh the task — what are comments, why are they always zero?
    //   label: 'result.event.comments',
    //   field: 'comments',
    //   type: 'number',
    //   extraClasses: 'left-border',
    // },
    {
      label: 'result.event.views',
      field: 'views',
      type: 'number',
      extraClasses: 'left-border',
    },
    {
      label: 'result.event.favorites',
      field: 'favorites',
      type: 'number',
      extraClasses: 'left-border',
    },
    {
      label: 'result.event.messages',
      field: 'messages',
      type: 'number',
      extraClasses: 'left-border',
    },
    {
      label: 'result.event.meetings',
      field: 'meetings',
      type: 'number',
      extraClasses: 'left-border',
    },
    {
      label: 'result.event.meetings_held',
      field: 'meetings_held',
      type: 'number',
      extraClasses: 'left-border',
    },
  ];
  public rows: TEventStatRow[] = [];
  public contacts: { contact_total: number; contact_views: number } = {
    contact_total: 0,
    contact_views: 0,
  };
  public meetings: { meetings_confirmed: number; meeting_total: number } = {
    meetings_confirmed: 0,
    meeting_total: 0,
  };
  public messages: { message_total: number } = {
    message_total: 0,
  };
  public notes: { note_total: number } = {
    note_total: 0,
  };
  public promopage: { promopages_total: number; promopages_views: number } = {
    promopages_total: 0,
    promopages_views: 0,
  };
  public programs: { programs_total: number; programs_views: number } = {
    programs_total: 0,
    programs_views: 0
  };
  public polls: TGetStatProgramQuestionnairesResponse[] = [];

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get canSeeEventStatistics(): boolean {
    return this.isEventOwner || this.isEventEditor;
  }

  public get isLoading(): boolean {
    return this.isEventLoading || this.isEventStatLoading;
  }

  public get processedPolls(): TGetStatProgramQuestionnairesResponse[] {
    return this.polls ? this.polls : [];
  }

  public get isEventOwner(): boolean {
    return EventHelper.isContactEventOrganizer(this.event, this.myself);
  }

  public get eventEditors(): TContact[] {
    return (this.event && this.event.editors) || [];
  }

  public get isEventEditor(): boolean {
    return !!(this.myself && !!this.eventEditors.find((editor: TContact) => editor.id === this.myself.id));
  }

  public get isExportEnabled(): boolean {
    return this.canSeeEventStatistics;
  }

  @Watch('event.id', { immediate: true })
  private async onEventIdChange(newVal: number): Promise<void> {
    if (!newVal) {
      return;
    }
    if (!this.canSeeEventStatistics) {
      this.$router.push({ name: 'result-personal' }).catch();
      return;
    }
    await this.$store.dispatch('statisticStore/getEvent', { eventId: this.eventId });
  }

  @Watch('eventStat', { immediate: true })
  private onEventStatChange(): void {
    this.setData();
  }

  public async exportEventContacts(exportFileFormat: string): Promise<void> {
    const filename = 'EventsWallet_event_' + this.eventId + '_contacts_statistics.' + exportFileFormat;
    const file = await this.$store.dispatch('statisticStore/exportEventContacts', {
      eventId: this.eventId,
      format: exportFileFormat
    });
    FileHelper.downloadFile(file, filename);
  }

  public async exportProgramContacts(program: TConferenceProgram, exportFileFormat: string): Promise<void> {
    const filename = 'EventsWallet_event_' + this.eventId + '_program_' + program.id + '_contacts_statistics.' + exportFileFormat;
    const file = await this.$store.dispatch('statisticStore/exportContactsWhoViewedAProgram', {
      eventId: this.eventId,
      conferenceId: program.conference_id,
      programId: program.id,
      format: exportFileFormat
    });
    FileHelper.downloadFile(file, filename);
  }

  public async exportPollStats(exportFileFormat: string): Promise<void> {
    const filename = 'EventsWallet_event_' + this.eventId + '_poll_statistics.' + exportFileFormat;
    const file = await this.$store.dispatch('statisticStore/exportPolls', {
      eventId: this.eventId,
      format: exportFileFormat
    });
    FileHelper.downloadFile(file, filename);
  }

  public async exportSessionOnlineCheck(programId: number, conferenceId: number, exportFileFormat: string): Promise<void> {
    const filename = 'EventsWallet_event_' + this.eventId + '_poll_statistics.' + exportFileFormat;

    const file = await this.$store.dispatch('statisticStore/exportSessionOnlineCheck', {
      eventId: this.eventId,
      conferenceId: conferenceId,
      programId: programId,
      format: exportFileFormat
    });
    FileHelper.downloadFile(file, filename);

  }

  public getTableCellClass(name: string): string {
    switch (name) {
      case 'photos':
      case 'comments':
      case 'messages':
        return 'left-border total-color';
      case 'files':
      case 'views':
      case 'meetings':
        return 'left-border high-color';
      case 'contacts':
      case 'products':
      case 'meetings_held':
        return 'left-border low-color';
      default:
        return null;
    }
  }

  public setData(): void {

    if (!this.eventStat) {
      return;
    }

    this.contacts.contact_total = this.eventStat.contact_total;
    this.contacts.contact_views = this.eventStat.contact_views;
    this.meetings.meetings_confirmed = this.eventStat.meetings_confirmed;
    this.meetings.meeting_total = this.eventStat.meeting_total;
    this.messages.message_total = this.eventStat.message_total;
    this.notes.note_total = this.eventStat.note_total;
    this.promopage.promopages_total = this.eventStat.promopages_total;
    this.promopage.promopages_views = this.eventStat.promopages_views;
    this.programs.programs_total = this.eventStat.programs_total;
    this.programs.programs_views = this.eventStat.programs_views;

    this.eventStat.promopage_list.forEach(item => {
      this.rows.push({
        name: item.promopage_title,
        contacts: item.stat.contacts,
        photos: item.stat.photos,
        files: item.stat.files,
        products: item.stat.products,
        // comments: item.stat.comments, // TODO: refresh the task — what are comments, why are they always zero?
        views: item.stat.views,
        favorites: item.stat.favorites,
        messages: item.stat.messages,
        meetings: item.stat.meetings,
        meetings_confirmed: item.stat.meetings_confirmed,
      });
    });
  }

  public async getSessionRatings(): Promise<void> {
    for (let confIndex = 0; confIndex < this.eventStat.conference_list.length; confIndex++) {
      const programs: TProgramListItem[] = this.eventStat.conference_list[confIndex].program_list;
      for (let programIndex = 0; programIndex < programs.length; programIndex++) {
        let result: TConferenceProgramRating;
        try {
          result = await promoProgramApi.getConferenceProgramRating({
            eventId: this.eventId,
            conferenceId: this.eventStat.conference_list[confIndex].conference_id,
            programId: programs[programIndex].program.id
          });
        } catch (error) {
          result = { rating: 0 };
        }
        if (result) {
          this.sessionRatings[programs[programIndex].program.id] = result.rating;
        }
      }
    }
    this.isRatingsLoaded = true;
  }

  public getProgramItemRating(programItem: TProgramListItem): string {
    const programId = programItem.program.id;

    if ( Object.prototype.hasOwnProperty.call(this.sessionRatings, programId)) {
      const rating = this.sessionRatings[programId];
      if (rating <= 0) {
        return '';
      } else if (Math.floor(rating) === rating) {
        return this.sessionRatings[programId].toFixed(0);
      } else {
        let result: string = this.sessionRatings[programId].toFixed(2);
        if (result[result.length - 1] === '0') {
          result = result.substr(0, result.length - 1);
        }
        return result;
      }
    }

    return '';
  }

  public selectEventStatTab(tabName: eventStatTabNames): void {
    this.eventStatCurrentTab = tabName;

    if (tabName === eventStatTabNames.SESSIONS) {
      this.getSessionRatings();
    } else if (tabName === eventStatTabNames.POLLS) {
      this.getPollStats();
    }
  }

  public async getPollStats(): Promise<void> {
    const programs: TProgramListItem[] = [];

    this.eventStat.conference_list.forEach(c => {
      const programList: TProgramListItem[] = c.program_list ? c.program_list : [];
      if (programList.length) {
        programs.push(...programList);
      }
    });

    for (let i = 0; i < programs.length; i++) {
      await statisticsApi.getStatProgramQuestionnaires({
        eventId: this.eventId,
        programId: programs[i].program.id
      })
        .then((resolve) => {
          if (resolve && resolve.length) {
            this.polls.push(...resolve);
          }
        })
        .catch(() => {
          /* ignore */
        });
    }

    this.isPollStatsLoaded = true;
  }

  public getPercentage(per: number, cent: number): number {
    if (per === 0 || cent === 0) {
      return 0;
    }
    return per / cent * 100;
  }

  public getAnswerPercentage(question: any, answerOptionResponsesAmount: number): string {
    let totalQuestionResponses = 0;
    question.answer_option_list.forEach((answerOption: any): void => {
      totalQuestionResponses += (answerOption.participant_amount as number);
    });
    const percentage = this.getPercentage(answerOptionResponsesAmount, totalQuestionResponses);
    let result = percentage.toFixed(0);
    if (Math.floor(percentage) < percentage) {
      result = percentage.toFixed(2);
    }
    return result;
  }

  public isProgramContactsExportButtonDisabled(programViews: number): boolean {
    return programViews < 1;
  }

  public getProgramSessionsSortedByStartTime(conferenceProgramList: TProgramListItem[]): TProgramListItem[] {
    return _cloneDeep(conferenceProgramList).sort((a, b) => {
      const dateStart_a = new Date(a.program.date_start);
      const dateStart_b = new Date(b.program.date_start);
      return dateStart_a.getTime() - dateStart_b.getTime();
    });
  }

  public async exportResultEventCompany(exportFileFormat: string): Promise<void> {
    const filename = 'EventsWallet_event_' + this.eventId + '_event_company_statistics.' + exportFileFormat;
    const file = await this.$store.dispatch('statisticStore/exportResultEventCompany', {
      eventId: this.eventId,
      format: exportFileFormat
    });
    FileHelper.downloadFile(file, filename);
  }

}
